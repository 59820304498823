import { handleValidation } from '@helpers/handleValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import { getAddressByPostcode } from '@providers/address';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import InputField from '../../InputField';
import AddressLookupResult from '../AddressLookupResult';
import { validationSchema } from './validation';

function AddressByPostcode({
  address,
  setAddress,
  isBillingAddress,
  isValid,
  setIsValid,
  setAddressNotFound,
}) {
  const [addressLookupParams, setAddressLookupParams] = useState({
    postcode: '',
    number: '',
    numberSuffix: '',
  });
  const [showResult, setShowResult] = useState(false);
  const [showNotFound, setShowNotFound] = useState(false);
  const debounceTimer = useRef(null);

  const {
    register,
    formState: { errors },
    trigger,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  function handleValidationWrapper(e, error, field) {
    handleValidation(e, error, trigger, setIsValid);
    setAddressLookupParams((prevState) => ({
      ...prevState,
      [field]: e.target.value.replace(/\s+/g, ''),
    }));
  }

  function handleOnKeyUp(e, field) {
    handleValidationWrapper(e, errors[field]);
    setAddressLookupParams((prevState) => ({
      ...prevState,
      [field]: e.target.value.replace(/\s+/g, ''),
    }));
  }

  async function handleRequest() {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(async () => {
      const response = await getAddressByPostcode(
        addressLookupParams.postcode,
        addressLookupParams.number,
        addressLookupParams.numberSuffix,
      );
      if (response && response.status === 200) {
        setAddress({
          ...address,
          city: response.data.city,
          street: response.data.street,
          postcode: response.data.postcode,
          number: response.data.number,
          numberSuffix: response.data.number_suffix,
          mailLines: response.data.mail_lines,
          isPoBox: response.data.is_po_box,
        });
        setShowResult(true);
        setShowNotFound(false);
        setAddressNotFound(false);
      } else if (response && response.status === 404) {
        setShowResult(false);
        setShowNotFound(true);
        setAddressNotFound(true);
      }
    }, 500);
  }

  useEffect(() => {
    if (isValid['postcode']) {
      document.getElementById('number').focus();
    }
  }, [isValid['postcode']]);

  useEffect(() => {
    if (
      isValid['postcode'] &&
      isValid['number'] &&
      addressLookupParams.postcode &&
      addressLookupParams.number
    ) {
      handleRequest();
    }
  }, [isValid, addressLookupParams]);

  useEffect(() => {
    if (address) {
      setValue('postcode', address?.postcode);
      setValue('number', address?.number);
      setValue('numberSuffix', address?.numberSuffix || address?.number_suffix);
    }
  }, [address]);

  return (
    <div className="flex flex-col gap-6">
      {/* AddressByPostcode */}
      <div className="flex flex-col justify-between gap-4 md:flex-row md:gap-8">
        <div className="md:w-3/4">
          <InputField
            id="postcode"
            label="Postcode"
            name="postcode"
            type="text"
            overrideClass="uppercase"
            register={register}
            errors={errors}
            onBlur={(e) => {
              handleValidationWrapper(e, errors?.postcode, 'postcode');
            }}
            onKeyUp={(e) => {
              handleOnKeyUp(e, 'postcode');
            }}
            message={errors.postcode?.message}
            isValid={isValid['postcode']}
          />
        </div>
        <InputField
          id="number"
          label="Huisnummer"
          name="number"
          type="text"
          register={register}
          errors={errors}
          onBlur={(e) => {
            handleValidationWrapper(e, errors?.number, 'number');
          }}
          onKeyUp={(e) => {
            handleOnKeyUp(e, 'number');
          }}
          onKeyPress={(e) => {
            if (!/[0-9]/.test(e.key)) {
              e.preventDefault();
            }
          }}
          message={errors.number?.message}
          isValid={isValid['number']}
          disabled={!isValid['postcode'] || errors?.postcode}
        />
        <InputField
          id="numberSuffix"
          label="Toevoeging"
          name="numberSuffix"
          type="text"
          register={register}
          errors={errors}
          onBlur={(e) => {
            handleValidationWrapper(e, errors?.numberSuffix, 'numberSuffix');
          }}
          onKeyUp={(e) => {
            handleOnKeyUp(e, 'numberSuffix');
          }}
          message={errors.numberSuffix?.message}
          isValid={isValid['numberSuffix']}
          disabled={!isValid['postcode'] || errors?.postcode}
        />
      </div>
      {showResult && address?.mailLines && (
        <AddressLookupResult
          mailLines={address?.mailLines}
          isBillingAddress={isBillingAddress}
          isLoading={false}
          country={address.country}
        />
      )}

      {showNotFound && (
        <div className="rounded-md bg-functional-error-light px-6 py-4 text-left font-primary text-sm font-bold tracking-wide text-functional-error/60">
          Het adres bestaat niet
        </div>
      )}
    </div>
  );
}

export default AddressByPostcode;
