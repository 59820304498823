import InputField from '@elements/forms/InputField';
import { validationSchema } from './validation';

import { handleValidation } from '@helpers/handleValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

function AddressByManualInput({ address, setAddress, isValid, setIsValid }) {
  const [mailLines, setMailLines] = useState(['', '']);

  const {
    register,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  function handleValidationWrapper(e, error) {
    handleValidation(e, error, trigger, setIsValid);
  }

  function handleMailLines(e, field) {
    handleValidationWrapper(e, errors[field]);

    const updatedAddress = { ...address, [field]: e.target.value };
    setAddress(updatedAddress);

    let updatedMailLines = [...mailLines];

    if (field === 'addressLine1' || field === 'addressLine2') {
      updatedMailLines[0] =
        `${updatedAddress.addressLine1 || ''} ${updatedAddress.addressLine2 || ''}`.trim();
    } else if (field === 'postcode' || field === 'city') {
      updatedMailLines[1] = `${updatedAddress.postcode || ''} ${updatedAddress.city || ''}`.trim();
    }

    setMailLines(updatedMailLines);

    const newAddress = { ...updatedAddress, mailLines: updatedMailLines };
    setAddress(newAddress);

    if (isValid['addressLine1'] && isValid['city']) {
      setIsValid((prevIsValid) => ({ ...prevIsValid, address: true }));
    } else {
      setIsValid((prevIsValid) => ({ ...prevIsValid, address: false }));
    }
  }

  function toggleisPoBox(e) {
    handleValidationWrapper(e, errors['isPoBox']);
    const updatedAddress = { ...address, isPoBox: e.target.checked };
    setAddress(updatedAddress);
  }

  return (
    <div className="flex flex-col gap-6">
      <InputField
        label="Adresregel 1"
        name="addressLine1"
        type="text"
        placeholder="Straat adres en huisnummer"
        register={register}
        onKeyUp={(e) => handleMailLines(e, 'addressLine1')}
        isValid={isValid['addressLine1']}
        message={errors.addressLine1?.message}
        errors={errors}
      />
      <InputField
        label="Adresregel 2"
        name="addressLine2"
        type="text"
        placeholder="Appartement, gebouw, eenheid etc. (optioneel)"
        register={register}
        onKeyUp={(e) => handleMailLines(e, 'addressLine2')}
        isValid={isValid['addressLine2']}
        message={errors.addressLine2?.message}
        errors={errors}
      />
      <InputField
        label="Postcode"
        name="postcode"
        type="text"
        placeholder="Postcode"
        register={register}
        onKeyUp={(e) => handleMailLines(e, 'postcode')}
        isValid={isValid['postcode']}
        message={errors.postcode?.message}
        errors={errors}
      />
      <InputField
        label="Stad"
        name="city"
        type="text"
        placeholder="Stad"
        register={register}
        onKeyUp={(e) => handleMailLines(e, 'city')}
        isValid={isValid['city']}
        message={errors.city?.message}
        errors={errors}
      />
      {/* isPoBox field */}
      <div className="flex gap-2">
        <input
          type="checkbox"
          id="isPoBox"
          name="isPoBox"
          className="accent-accent"
          {...register('isPoBox')}
          onChange={toggleisPoBox}
        />
        <label htmlFor="isPoBox" className="text-sm">
          Dit adres is voor een postbus
        </label>
      </div>
      {/* unsure if this is needed for something or if something is missing. commenting it out */}
      {/* <div className="ml-[22px] font-primary text-sm font-light tracking-wide text-functional-error"></div> */}
    </div>
  );
}

export default AddressByManualInput;
